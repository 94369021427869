<!-- =========================================================================================
    File Name: Profile.vue
    Description: Profile Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="profile-page">
    <!-- COL AREA -->
    <div class="vx-row">
      <!-- COL 1 -->
      <div class="vx-col w-full lg:w-1/4">
        <!-- ABOUT CARD -->
        <vx-card title="About" class="mt-base">
          <!-- ACTION SLOT -->
          <div class="d-table mx-auto" v-if="profileInfo.user_pic != ''">
            <img :src="profileInfo.user_pic" class="img-fluid" alt="Image" />
          </div>

          <div v-else class="initials">{{ firstChar }}</div>
        </vx-card>
      </div>

      <!-- COL 2 -->
      <div class="vx-col w-full lg:w-1/2">
        <vx-card class="mt-base">
          <div>
            <span class="table-edit-icon float-right">
              <feather-icon
                class
                icon="EditIcon"
                svgClasses="h-7 w-7"
                @click="showModal"
              />
            </span>
          </div>
          <!-- :id="'popmodal'+params.data.user_id" -->
          <vs-popup
            :id="'popmodalProfile'"
            class="holamundo editModal"
            title="Update User Details"
            :active.sync="popupActive"
          >
            <form ref="testingRef">
              <FocusLock>
                <div class="vx-row">
                  <!-- LEFT SIDE -->

                  <!-- name -->
                  <div class="col-12">
                    <div class="vx-row mb-6">
                      <div class="col-4 text-right align-self-center">
                        <h5 class="m-6">Upload Image</h5>
                      </div>
                      <div class="col-7">
                        <div class="mx-10">
                          <label for="updateProfilePicture" class="mx-12">
                            <img
                              v-if="profileInfo.user_pic != ''"
                              :src="profileInfo.user_pic"
                              class="profile-img"
                            />
                            <img
                              v-else
                              src="https://img.gokozo.com/bd053e422b601bb5_org.png"
                              class="profile-img"
                            />
                            <div class="comp-logo-edit mt-2">
                              <i class="text-center material-icons align-middle"
                                >arrow_upward</i
                              >
                              update
                            </div>
                          </label>
                          <input
                            type="file"
                            @change="uploadFile($event)"
                            class="d-none"
                            id="updateProfilePicture"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="vx-row mb-6">
                      <div class="col-4 text-right align-self-center">
                        <h5 class="mb-0">Organization Name*</h5>
                      </div>
                      <div class="col-7">
                        <div class>
                          <vs-input
                            v-validate="'required'"
                            name="Name"
                            v-model="form.organizations_name"
                            class="w-full"
                          />
                          <span
                            class="text-danger text-sm"
                            v-show="errors.has('Name')"
                            >{{ errors.first("Name") }}</span
                          >
                        </div>
                      </div>
                    </div>

                    <!-- email-->
                    <div class="vx-row mb-6">
                      <div class="col-4 text-right align-self-center">
                        <h5 class="mb-0">Email *</h5>
                      </div>
                      <div class="col-7">
                        <div class>
                          <vs-input
                            name="Email"
                            disabled
                            v-model="form.user_email"
                            class="w-full"
                          />
                          <span
                            class="text-danger text-sm"
                            v-show="errors.has('name')"
                            >{{ errors.first("name") }}</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="vx-row mb-6">
                      <div class="col-4 text-right align-self-center">
                        <h5 class="mb-0">Password *</h5>
                      </div>
                      <div class="col-7">
                        <div class>
                          <vs-input
                            name="password"
                            v-model="newForm.password"
                            ref="password"
                            class="w-full"
                          />
                          <span
                            class="text-danger text-sm"
                            v-show="errors.has('password')"
                            >{{ errors.first("password") }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="vx-row mb-6">
                      <div class="col-4 text-right align-self-center">
                        <h5 class="mb-0">Confirm Password *</h5>
                      </div>
                      <div class="col-7">
                        <div class>
                          <!--  -->
                          <vs-input
                            v-validate="'confirmed:password'"
                            name="Password Confirmation"
                            v-model="newForm.confirmPassword"
                            class="w-full"
                          />
                          <span
                            v-if="newForm.confirmPassword"
                            class="text-danger text-sm"
                            v-show="errors.has('Password Confirmation')"
                            >{{ errors.first("Password Confirmation") }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <!-- Phone -->
                    <div class="vx-row mb-6">
                      <div class="col-4 text-right align-self-center">
                        <h5 class="mb-0">Phone *</h5>
                      </div>
                      <div class="col-7">
                        <div class>
                          <vs-input
                            v-validate="'required|numeric|max:14'"
                            name="Phone"
                            v-model="form.user_mobile"
                            class="w-full"
                            rules="alpha"
                          />
                          <span
                            class="text-danger text-sm"
                            v-show="errors.has('Phone')"
                            >{{ errors.first("Phone") }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <!-- address  -->
                    <div class="vx-row mb-6">
                      <div class="col-4 text-right align-self-center">
                        <h5 class="mb-0">Address *</h5>
                      </div>
                      <div class="col-7">
                        <div class>
                          <vs-textarea
                            v-model="form.user_address"
                            class="mb-0"
                            rows="4"
                          />
                          <span
                            class="text-danger text-sm"
                            v-show="errors.has('Address')"
                            >{{ errors.first("Address") }}</span
                          >
                        </div>
                      </div>
                    </div>

                    <!-- gender -->
                    <!-- <div class="vx-row mb-6">
                      <div class="col-4 text-right align-self-center">
                        <h5 class="mb-0">Gender*</h5>
                      </div>
                      <div class="col-7">
                        <ul class="centerx list-inline">
                          <li class="list-inline-item">
                            <vs-radio v-model="profileInfo.user_gender" vs-value="Male">Male</vs-radio>
                          </li>
                          <li class="list-inline-item">
                            <vs-radio v-model="profileInfo.user_gender" vs-value="Female">Female</vs-radio>
                          </li>
                        </ul>
                      </div>
                    </div>-->

                    <!-- DOB-->
                    <!-- <div class="vx-row mb-6">
                      <div class="col-4 text-right">
                        <h5 class="mb-0">Date of birth</h5>
                      </div>
                      <div class="col-7">
                        <div class="rounded">
                          class="bg-white"
                          <flat-pickr v-model="profileInfo.user_dob" />
                        </div>
                        <span
                          class="text-danger text-sm"
                          v-show="errors.has('Birth date')"
                        >{{ errors.first('birth date') }}</span>
                      </div>
                    </div>-->

                    <!-- Password -->
                    <!-- <div class="vx-row mb-6">
                      <div class="col-4 text-right align-self-center">
                        <h5 class="mb-0">Password</h5>
                      </div>
                      <div class="col-7">
                        <div class>
                          <vs-input
                            name="Password"
                            v-model="user_password"
                            class="w-full text-danger"
                          />
                          <span
                            class="text-danger text-sm"
                            v-show="errors.has('new user password')"
                          >{{ errors.first('new user password') }}</span>
                        </div>
                      </div>
                    </div>-->
                  </div>
                </div>

                <!-- SUBMIT BUTTON -->
                <div class="vx-row">
                  <div class="vx-col mx-auto">
                    <!-- :disabled="submitStatus" -->
                    <!-- :disabled="submitStatus" -->
                    <vs-button
                      type="filled"
                      @click.prevent="editForm"
                      class="mr-3"
                      >Update</vs-button
                    >

                    <!-- <vs-button color="warning" type="border" class @click.prevent="clearForm">Reset</vs-button> -->
                  </div>
                </div>
              </FocusLock>
            </form>
          </vs-popup>
          <div>
            <!-- USER NAME -->
            <div class="mt-5">
              <h6 class="font-bold">Name:</h6>
              <p>{{ profileInfo.user_name }}</p>
            </div>
            <!-- OTEHR DATA -->
            <div class="mt-5">
              <div class="row">
                <div class="col-md-4">
                  <h6 class="font-bold">Role</h6>
                  <p>{{ profileInfo.user_role }}</p>
                </div>
                <div class="col-md-4">
                  <h6 class="font-bold">Date of Birth:</h6>
                  <p>{{ profileInfo.user_dob }}</p>
                </div>

                <div class="col-md-4">
                  <h6 class="font-bold">Gender</h6>
                  <p>{{ profileInfo.user_gender }}</p>
                </div>
              </div>
            </div>

            <div class="mt-5">
              <div class="row">
                <div class="col-md-6">
                  <h6 class="font-bold">Email:</h6>
                  <p>{{ profileInfo.user_email }}</p>
                </div>

                <div class="col-md-6">
                  <h6 class="font-bold">Mobile No.</h6>
                  <p>{{ profileInfo.user_mobile }}</p>
                </div>
              </div>
            </div>

            <div class="mt-5">
              <h6 class="font-bold">Organization:</h6>
              <p>{{ profileInfo.organizations_name }}</p>
            </div>

            <div class="mt-5">
              <h6 class="font-bold">Address:</h6>
              <p>{{ profileInfo.user_address }}</p>
            </div>
          </div>

          <div class="border-top profile-minH">
            <div class="col-10 mx-auto">
              <table
                class="table mb-4 mt-3"
                v-for="(CompanyItem, CompanyIndex) in companyData"
                :key="CompanyIndex"
              >
                <thead>
                  <tr>
                    <th
                      colspan="2"
                      class="
                        text-center
                        h4
                        font-weight-bold
                        bg-primary
                        text-white
                      "
                      scope="col"
                    >
                      {{ CompanyItem.company_name }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Department</th>
                    <td>{{ CompanyItem.department_name }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Designation</th>
                    <td>{{ CompanyItem.designation_name }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </vx-card>
      </div>

      <!-- COL 3 -->
    </div>

    <!-- <div class="vx-row">
            <div class="vx-col w-full">
                <div class="flex justify-center mt-base">
                    <vs-button id="button-load-more-posts" class="vs-con-loading__container" @click="loadContent">Load More</vs-button>
                </div>
            </div>
    </div>-->
  </div>
</template>

<script>
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import UserService from "@/services/UserService";
import axios from "axios";
import { BASEURL } from "@/config/index";

export default {
  data() {
    return {
      userRole: null,
      tempToken: window.localStorage.getItem("TempToken"),
      config: {
        maxDate: new Date().fp_incr(-6600),
        format: "d-m-Y",
        altFormat: "d-m-Y",
        altInput: true,
      },
      submitStatus: false,
      isPasswordUpdated: false,
      popupActive: false,
      profileInfo: {},
      form: {
        organization_logo: "-",
        user_email: "",
        // // organization_name: "",
        user_mobile: null,

        user_address: "",
      },

      newForm: {
        confirmPassword: "",
        password: "",
      },
      companyData: [],
      isNavOpen: true,
      userPoll: "",
      firstChar: "",
      navbarSearchAndPinList: this.$store.state.navbarSearchAndPinList,
      userInfo: {
        profileImg: "user-13.jpg",
      },
      mediaExtensions: {
        img: ["jpg", "jpeg", "png", "bmp", "gif", "exif", "tiff"],
        video: ["avi", "flv", "wmv", "mov", "mp4", "3gp"],
      },

      wasSidebarOpen: null,
    };
  },

  watch: {
    form: {
      handler: function (newVal, oldVal) {
        if (this.count > 0) {
          this.submitStatus = false;
        }
        this.count++;
      },
      deep: true,
    },
    // user_password: {
    //   handler: function(newVal, oldVal) {
    //     if (this.count > 0) {
    //       this.isPasswordUpdated = true;
    //       this.submitStatus = false;
    //     }
    //     this.count++;
    //   },
    //   deep: true
    // }
  },
  computed: {
    mediaType() {
      return (media) => {
        if (media.img) {
          const ext = media.img.split(".").pop();
          if (this.mediaExtensions.img.includes(ext)) return "image";
        } else if (media.sources && media.poster) {
          // other validations
          return "video";
        }
      };
    },
    // playerOptions() {
    //   return media => {
    //     return {
    //
    //       height: "360",
    //       fluid: true,
    //       autoplay: false,
    //       muted: true,
    //       language: "en",
    //       playbackRates: [0.7, 1.0, 1.5, 2.0],
    //       sources: media.sources,
    //       poster: media.poster
    //     };
    //   };
    // }
  },
  methods: {
    uploadFile(event) {
      let that = this;
      let formData = new FormData();
      formData.append("file", event.target.files[0]);
      axios
        .post(BASEURL + "/v2/uploads", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (res) {
          console.log("res :", res);
          that.form.organization_logo = res.data.data.image_path;
        })
        .catch(function (err) {
          this.$vs.notify({
            title: "Error!",
            text: error,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },

    editForm: function () {
      console.log(this.form);
      if (this.newForm.password != "" && this.newForm.confirmPassword != "") {
        this.editPssword();
        this.submitForm();
      } else {
        this.submitForm();
      }
    },

    submitForm: function () {
      this.$validator.validateAll().then((result) => {
        // this.$vs.loading();
        console.log("result", result);
        if (result) {
          let id = this.profileInfo.organization_id;
          let payload = {
            organization_logo: this.form.organization_logo || "-",
            organization_name: this.form.organizations_name,
            organization_mobile: this.form.user_mobile,
            organization_address: this.form.user_address,
            organization_active: 1,
            // user_name: this.profileInfo.organi_name,
            // user_gender: this.profileInfo.user_gender,
            // user_dob: this.profileInfo.user_dob
            // user_email: this.profileInfo.user_email
            // // role_id: this.form.role_id,
            // city_id: this.form.city_id,
            // user_pic: this.form.user_pic,
            // user_password: this.user_password,
          };
          //   close;

          UserService.editProfileInfo(payload, id)
            .then((response) => {
              // this.$vs.loading.close();
              this.submitStatus = false;
              const { data } = response;
              this.popupActive = false;
              if (data.Status) {
                this.$vs.notify({
                  title: "Updated!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "success",
                });
                this.$forceUpdate();
                this.clearForm();
              } else {
                // this.$vs.loading.close();
                console.log("data :", data);
                this.$vs.notify({
                  title: "Error!",
                  text: data.error.message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "warning",
                });
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
              this.submitStatus = true;
              console.log("error:", error);
            });
        } else {
          //form have errors
        }
      });
    },

    editPssword: function () {
      let payload = {
        user_password: this.newForm.password,
        user_email: this.form.user_email,
      };
      console.log("payloadpayloadpayloadpayload :", payload);
      UserService.updateProfileInfo(payload)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.$vs.notify({
              title: "Updated!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
            this.$forceUpdate();
            this.clearForm();
            this.newForm.password = "";
            this.newForm.confirmPassword = "";
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: data.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },

    showModal() {
      console.log("object :");
      this.popupActive = true;
    },
    getProfileDetails: function () {
      UserService.getProfileInfo()
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            this.profileInfo = data.data;
            this.form = data.data;
            window.localStorage.setItem("UserType", data.data.checkUserType);
            this.companyData = data.companyData;

            let splitter = this.profileInfo["user_name"]
              ? this.profileInfo.user_name.split(" ")
              : "";
            if (splitter.length > 1) {
              this.firstChar =
                splitter[0].charAt(0).toUpperCase() +
                splitter[1].charAt(0).toUpperCase();
            } else {
              this.firstChar =
                splitter[0].charAt(0).toUpperCase() +
                splitter[0].charAt(1).toUpperCase();
            }
          }
        })
        .catch((err) => {});
    },
    loadContent() {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: "#button-load-more-posts",
        scale: 0.45,
      });
      setTimeout(() => {
        this.$vs.loading.close("#button-load-more-posts > .con-vs-loading");
      }, 3000);
    },
  },
  components: {
    videoPlayer,
    flatPickr,
  },
  mounted() {
    this.getProfileDetails();
    // this.wasSidebarOpen = this.$store.state.reduceButton;
    // this.$store.commit("TOGGLE_REDUCE_BUTTON", true);
  },
  beforeDestroy() {
    if (!this.wasSidebarOpen) this.$store.commit("TOGGLE_REDUCE_BUTTON", false);

    let _id = document.getElementById("popmodalProfile");
    _id.setAttribute("style", "display: none;");
  },
  beforeMount() {
    this.userRole = localStorage.getItem("UserRole");
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuesax/pages/profile.scss";

.table-edit-icon {
  cursor: pointer;
  height: 40px;
  width: 40px;
  color: #fff;
  display: flex;
  margin: 1px auto;
  align-items: right;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  background: rgba(var(--vs-primary), 1);
}
</style>
